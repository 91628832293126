<template>
    <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
        <v-col class="text-start mt-5 mb-3">
            <v-alert
                text
                dense
                color="#8061c2"
                border="left"
                class="pl-5"
            >
                <h1 class="title mt-1">Códigos Asignados</h1>
                <p class="desc mt-0 mb-1">Listado de registro de códigos asignados.</p>
            </v-alert>
        </v-col>
        <v-row class="mx-3">
            <v-col cols="12" md="4" class="px-0">
                <v-text-field
                v-model="search"
                color="#8061c2"
                label="Buscar usuarios"
                append-icon="mdi-magnify"
                single-line
                hide-details
                outlined
                class="mb-2 mx-0 px-0"
                @input="onSearch"
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="4" class="px-md-2 px-0">
                <v-select
                v-model="orderBy"
                :items="orderByOptions"
                label="Ordenar por"
                outlined
                @change="onOrderChange"
                ></v-select>
            </v-col>

            <v-col cols="12" md="4" class="px-0">
                <v-select
                v-model="orderDirection"
                :items="orderDirectionOptions"
                label="Dirección"
                outlined
                @change="onOrderChange"
                ></v-select>
            </v-col>
        </v-row>
        <v-data-table class="elevation-1 mx-3 px-2 py-4" :headers="headers" :items="codesAssign" :loading="loadingCodesAssign"
        :items-per-page="perPage" :page.sync="page" @update:page="onPageChange" @update:items-per-page="changePerPage"
        :footer-props="{itemsPerPageOptions: [50, 100, 200, 300, -1],showFirstLastPage: true}"></v-data-table>
    </v-col>
</template>

<script>
import axios from "@/axios.js";
export default {
    data() {
        return {
            headers: [
                { text: 'Asignado', value: 'email' },
                { text: 'Código', value: 'code', align: 'start' },
                { text: 'Fecha', value: 'created_at_owner' },
            ],
            search:'',
            orderBy: 'created_at',
            orderDirection: 'desc',
            orderByOptions: [
                { text: 'Fecha registro', value: 'created_at_owner' },
            ],
            orderDirectionOptions: [
                { text: 'Mayor a menor', value: 'desc' },
                { text: 'Menor a mayor', value: 'asc' },
            ],
            page: 1,
            perPage: 50,
            codesAssign:[],
            loadingCodesAssign:false
        }
    },
    watch: {
        orderBy(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.getCodesAssign();
            }
        },
        orderDirection(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.page = 1;
                this.getCodesAssign();
            }
        },
    },
    created(){
        this.getCodesAssign();
    },
    methods:{
        async getCodesAssign(){
            this.loadingCodesAssign = true;
            try {
            const response = await axios.get('/admin/get-codes-assign', {
                params: {
                    page: this.page,
                    per_page: this.perPage,
                    search: this.search,
                    order_by: this.orderBy,
                    order_direction: this.orderDirection,
                }
            });
            console.log(response)
            this.codesAssign = response.data.getCodesAssign.data;
            } catch (error) {
                console.error(error);
            } finally {
                this.loadingCodesAssign = false;
            }
        },
        onSearch() {
            this.page = 1;
            this.getCodesAssign();
        },
        onOrderChange() {
            this.page = 1;
            this.getCodesAssign();
        },
        onPageChange() {
            this.getCodesAssign();
        },
    }
}

</script>

<style scoped>
</style>